import React, {useState} from "react";
import {motion} from "framer-motion";
import gif from "../media/underwater2.gif"
import SubscriptionButton from "../components/SubscriptionButton";
import Donate from "../components/Donate";

import FadeIn from "../components/FadeIn";

export default function ContributeDesktop() {
    const isXlScreen = window.matchMedia("(min-width: 1200px)").matches;
    const size = isXlScreen ? 50 : 40;
    const [subscribed, setSubscribed] = useState(false);
    const [donate, setDonate] = useState(false);

    return (
        <FadeIn duration={1.7}>
            <div
                style={{
                    marginTop: isXlScreen ? "0rem" : "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    position: "relative"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: isXlScreen ? "3rem" : "2rem",
                        zIndex: 10
                    }}
                >
                    {!donate && (
                        <SubscriptionButton
                            subscribed={subscribed}
                            setSubscribed={setSubscribed}
                        />
                    )}
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: donate ? "3rem" : "8rem",
                        zIndex: 10
                    }}
                >
                    {!subscribed && (
                        <Donate
                            onComplete={() => {
                            }}
                            setSubscribed={setDonate}
                            subscribed={donate}
                        />
                    )}
                </div>
                <motion.div
                    style={{
                        position: "absolute",
                        left: `calc(50% - ${size / 2}vh)`,
                        bottom: `calc(50% - ${size / 2}vh)`,
                        width: `${size}vh`,
                        height: `${size}vh`,
                        borderRadius: "50%",
                        overflow: "hidden",
                        zIndex: 100
                    }}
                    animate={{
                        width: `${size}vh`,
                        height: `${size}vh`,
                        left: `calc(50% - ${size / 2}vh)`,
                        bottom: `calc(50% - ${size / 2}vh)`
                    }}
                >
                    <img src={gif} alt="example gif" style={{width: "100%"}}/>
                </motion.div>
                <motion.div
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        borderRadius: "50%",
                        backgroundImage: `linear-gradient(to bottom, ${"#26a6d7"}, ${"#e392d6"})`,
                        width: `${size}vh`,
                        height: `${size}vh`,
                        left: `calc(50% - ${size / 2}vh)`,
                        bottom: `calc(50% - ${size / 2}vh)`,
                        backgroundPosition: "50% 90%", // Adjust the midpoint here
                    }}
                    animate={{
                        backgroundImage: subscribed || donate ? `linear-gradient(to bottom, ${"#f5eba1"}, ${"#ff9e5c"})` : `linear-gradient(to bottom, ${"#1bcee7"}, ${"#e392d6"})`,
                        transform: subscribed || donate ? `skew(-${60 / 1}deg)` : `skew(${60 / 3}deg)`,
                        transition: {type: "tween", duration: subscribed || donate ? 0.5 : 0.25},
                    }}
                />


                <div
                    style={{
                        zIndex: 0,
                        position: "absolute",
                        bottom: "0",
                        width: "100vw",
                        height: "calc(50vh - 1px)",
                        borderWidth: "1px",
                        backgroundColor: "aliceblue"
                        //backgroundImage: `linear-gradient(to bottom, ${"#eeeeee"}, ${"#ffffff"})`
                    }}
                />
                <div
                    style={{
                        zIndex: 3,
                        position: "absolute",
                        top: "0",
                        width: "100vw",
                        height: "50vh",
                        backgroundColor: "white"
                    }}
                ></div>
            </div>
        </FadeIn>
    );
}