import React, {StrictMode} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Buoy from "./pages/Buoy";
import Header from './components/Header';
import Contribute from "./pages/Contribute";
import ContributeDesktop from "./pages/ContributeDesktop";
import BuddyTracker from "./pages/BuddyTracker";
import HackadayPowerGerbers from "./pages/HackadayPowerGerbers";

const App = () => {
    const isXlScreen = window.matchMedia("(min-width: 1200px)").matches;

    return (
        <StrictMode>
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Buoy/>}/>
                    <Route path="power-gerbers" element={<HackadayPowerGerbers/>}/>
                    <Route path="buddytracker" element={<BuddyTracker/>}/>
                    <Route path="join" element={isXlScreen ? <ContributeDesktop/> : <Contribute/>}/>
                </Routes>
            </BrowserRouter>
        </StrictMode>
    );
};

export default App;
