import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import FadeIn from "./FadeIn";
import MailchimpForm from "./MailchimpForm";

const SubscriptionButton = (props) => {
    const [email, setEmail] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleSubscribeClick = () => {
        props.setSubscribed(true);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDiveInClick = () => {
        // Perform the desired action when "Dive In" button is clicked
        console.log("Diving in with email:", email);
    };

    const handleWhyJoinClick = () => {
        setShowModal(true);
    };

    const buttonAnimation = {
        width: props.subscribed ? "0%" : "100%",
        opacity: props.subscribed ? 0 : 1,
        transition: {
            duration: 1
        }
    };

    return (
        <div>
            {!props.subscribed ? (
                <motion.div style={buttonAnimation}>
                    <Button
                        variant="light"
                        onClick={handleSubscribeClick}
                        style={{
                            color: "black",
                            fontSize: "1.25rem",
                            padding: "0.1rem 5rem",
                            border: "1px solid gray",
                            borderWidth: "0px",
                            borderRadius: "5px"
                        }}
                    >
                        Join Us
                    </Button>
                </motion.div>
            ) : (
                <FadeIn duration={0.3}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <MailchimpForm />
                        <Button
                            onClick={handleWhyJoinClick}
                            style={{
                                fontSize: "1rem",
                                marginTop: "1rem",
                                alignSelf: "center",
                                backgroundColor: "white",
                                color: "black",
                                borderWidth: "0px"
                            }}
                        >
                            Why Join?
                        </Button>
                        <Button
                            variant="light"
                            style={{
                                fontSize: "1rem",
                                alignSelf: "center",
                                backgroundColor: "white",
                                color: "gray",
                                borderWidth: "0px",
                                marginTop:"1rem"
                            }}
                            onClick={()=>{
                                props.setSubscribed(false);
                            }}
                        >Cancel</Button>

                    </div>
                </FadeIn>
            )}

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Get involved!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Join us in building a vibrant community of ocean explorers, engineers, and scientists! Sign up today to:
                    <ul>
                        <li>Join our exclusive Discord server.</li>
                        <li>Exchange feedback on innovative design ideas in incubation.</li>
                        <li>Gain access to exclusive data.</li>
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SubscriptionButton;
