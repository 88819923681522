import { combineReducers } from "@reduxjs/toolkit";
import modelReducer from "./modelReducer";
import framerReducer from "./framerReducer";
import logsReducer from "./logsReducer";
const rootReducer = combineReducers({
    model: modelReducer,
    framer: framerReducer,
    logs: logsReducer,
});

export default rootReducer;
