import React from "react";
import ProjectPage from "./Project";
import colors from "../components/Colors";

const model = require("../media/BuoyPower.wrl");

const projectLogs = [
    { index: 0, title: "Project 1", description: "Description 1" },
    { index: 1, title: "Project 2", description: "Description 2" },
    { index: 2, title: "Project 3", description: "Description 3" },
];

export default function BuddyTracker(){
    return(
        <ProjectPage
            model={model}
            projectLogs={projectLogs}
            modelBackground = {`linear-gradient(0deg,${colors.plum} 0%, ${colors.robin} 100%)`}
            expandedColor={colors.plum}
            collapsedColor={colors.lightrobin}
        />
    )
}