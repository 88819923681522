import React, { useState } from 'react';
import axios from 'axios';

const MailchimpForm = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = event => setEmail(event.target.value);

    const handleSubmit = event => {
        event.preventDefault();

        axios.post("https://protonmail.us8.list-manage.com/subscribe/post", {
            email: email,
            u: "2581d56d95eb24eb325013ab4",
            id: "86848be9c1"
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        })
    };

    return (
        <div id="mc_embed_signup" style={{maxWidth:'600px', margin:'auto'}}>
            <form action="https://protonmail.us8.list-manage.com/subscribe/post?u=2581d56d95eb24eb325013ab4&amp;id=86848be9c1&amp;f_id=00bc0de0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <input
                        style={{
                            flex: 1,
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '5px 0 0 5px'
                        }}
                        type="email"
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                        required
                        placeholder="Email Address"
                    />
                    <input
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            background: '#f8f9fa',
                            color: '#343a40',
                            cursor: 'pointer',
                            borderRadius: '0 5px 5px 0'
                        }}
                        type="submit"
                        value="Dive In"
                    />
                </div>
            </form>
        </div>
    );
}

export default MailchimpForm;
