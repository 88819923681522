import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";

export default function Header() {
  return (
      <Navbar bg="light" expand="lg" className="navbar" style={{zIndex:1001, }}>
        <Container>
          <Navbar.Brand style={{ display: "flex", flexWrap: "nowrap" }}>
            <h3>DIY</h3>
            <h3 style={{ fontWeight: "bold", marginLeft: "0.0rem" }}>
              Aquanauts
            </h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link style={{ textAlign: "center" }} href="/">
                Home
              </Nav.Link>
              <Nav.Link style={{ textAlign: "center" }} href="/join">
                Contribute
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}
