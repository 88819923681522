import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import FadeIn from "../components/FadeIn";
import BoardViewer from "../components/GerberViewer/BoardViewer";
import 'typeface-lato';
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";

export default function HackadayPowerGerbers(props) {

    return (
        <div style={{
            width: '100wh',
            height: '100vh',
            backgroundColor:"rgb(38,38,35)",
            overflowY:"hidden",
        }}>
            <FadeIn duration={2}>
                <BoardViewer
                    svgList={[
                        "/svgoptimizer/BuoyPower-B_Cu.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-B_Mask.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-B_Silkscreen.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-Edge_Cuts.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-F_Cu.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-F_Mask.gbr-min.svg",
                        "/svgoptimizer/BuoyPower-F_Silkscreen.gbr-min.svg",

                    ]}
                />
            </FadeIn>
        </div>
    );
};