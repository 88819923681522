import React, { useState, useEffect, useRef } from "react";
import gif from "../media/underwater2.gif";
import critter from "../media/creature.png";
import SubscriptionButton from "../components/SubscriptionButton";
import Donate from "../components/Donate";
import FadeIn from "../components/FadeIn";

export default function Contribute() {
    const [subscribed, setSubscribed] = useState(false);
    const [donate, setDonate] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, []);


    const calculateHeight = () => {
        const windowHeight = window.innerHeight;
        const taskbarHeight = windowHeight - document.documentElement.clientHeight;
        return `calc((93vh - ${taskbarHeight}px)/1.75)`;
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    return (
        <FadeIn duration={1.7}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    overflow: "hidden",
                    position: "relative",
                    minHeight: "100vh",
                }}
            >
                <div
                    style={{
                        top: subscribed ? "10vh" : "13vh",
                        position: "absolute",
                    }}
                >
                    {!donate && (
                        <SubscriptionButton
                            onComplete={scrollToBottom}
                            setSubscribed={setSubscribed}
                            subscribed={subscribed}
                        />
                    )}
                </div>
                <div
                    style={{
                        top: donate ? "10vh" : "25vh",
                        position: "absolute",
                    }}
                >
                    {!subscribed && (
                        <Donate
                            onComplete={scrollToBottom}
                            setSubscribed={setDonate}
                            subscribed={donate}
                        />
                    )}
                </div>
                <div
                    style={{
                        bottom: 0,
                        left: 0,
                        overflow: "hidden",
                        position: "absolute",
                        width: "100%",
                        height: calculateHeight(),
                        borderRadius: "calc(50%)",
                    }}
                >
                    <img
                        src={gif}
                        alt="example gif"
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
            </div>
            <div ref={scrollRef}>
                <div
                    style={{
                        height: "10000vh",
                        background: "rgb(15,20,24)",
                        modelBackground: "linear-gradient(0deg,rgb(26,38,61) 0%, rgb(15,20,24) 100%)"

                    }}
                ></div>
                <div style={{height:"50vh", background:"pink"}}>
                    <img
                        src={critter}
                        alt="example gif"
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
            </div>
        </FadeIn>
    );
}
