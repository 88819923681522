import React, {useState} from "react";
import {Button, Form, ButtonGroup, Modal} from "react-bootstrap";
import {motion} from "framer-motion";
import FadeIn from "./FadeIn";

export default function ffDonate(props) {
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [stripeUrl, setStripUrl] = useState(null);

    const handleSubscribeClick = () => {
        props.setSubscribed(true);
    };

    const handleAmountClick = (amount) => {
        setSelectedAmount((prevAmount) => (prevAmount === amount ? null : amount));
    };

    const handleWhyJoinClick = () => {
        setShowModal(true);
    };

    function handleDonate(){
        const returnURL = window.location.href;
        let stripeurl = ""
        switch (selectedAmount){
            case "$5":
                stripeurl = 'https://donate.stripe.com/6oEcOmfxR1X76m4bIK';
                break;
            case "$10":
                stripeurl = 'https://donate.stripe.com/14k8y671l9pzfWE4gh';
                break;
            case "$20":
                stripeurl = 'https://donate.stripe.com/14k8y6adxfNXbGo9AD';
                break;
            case "$50":
                stripeurl = 'https://donate.stripe.com/00gaGe71latD7q8eUY';
                break;
            case "$100":
                stripeurl = 'https://donate.stripe.com/28o5lU0CXeJTcKs5kp';
                break;
        }
        props.onComplete();
        setTimeout(() => {
            window.location.href = stripeurl;
        }, 3000);
        localStorage.setItem('returnURL', returnURL);
    }

    const buttonAnimation = {
        width: props.subscribed ? "0%" : "100%",
        opacity: props.subscribed ? 0 : 1,
        transition: {
            duration: 1
        }
    };

    return (
        <Form>
            {!props.subscribed ? (
                <motion.div style={buttonAnimation}>
                    <Button
                        variant="light"
                        onClick={handleSubscribeClick}
                        style={{
                            color: "black",
                            fontSize: "1.25rem",
                            padding: "0.1rem 5rem",
                            border: "1px solid gray",
                            borderWidth: "0px",
                            borderRadius: "5px"
                        }}
                    >
                        Donate
                    </Button>
                </motion.div>
            ) : (
                <FadeIn duration={0.3}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ButtonGroup aria-label="Subscription Amount" variant="dark">
                            <Button
                                variant={selectedAmount === "$5" ? "dark" : "light"}
                                onClick={() => handleAmountClick("$5")}
                            >
                                $5
                            </Button>
                            <Button
                                variant={selectedAmount === "$10" ? "dark" : "light"}
                                onClick={() => handleAmountClick("$10")}
                            >
                                $10
                            </Button>
                            <Button
                                variant={selectedAmount === "$20" ? "dark" : "light"}
                                onClick={() => handleAmountClick("$20")}
                            >
                                $20
                            </Button>
                            <Button
                                variant={selectedAmount === "$50" ? "dark" : "light"}
                                onClick={() => handleAmountClick("$50")}
                            >
                                $50
                            </Button>
                            <Button
                                variant={selectedAmount === "$100" ? "dark" : "light"}
                                onClick={() => handleAmountClick("$100")}
                            >
                                $100
                            </Button>
                        </ButtonGroup>
                        {selectedAmount === null ? (
                            <Button
                                onClick={handleWhyJoinClick}
                                style={{
                                    fontSize: "1rem",
                                    marginTop: "1rem",
                                    alignSelf: "center",
                                    backgroundColor: "white",
                                    color: "black",
                                    borderWidth: "0px"
                                }}
                            >
                                Why Donate?
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={handleDonate}
                                    variant="outline-dark"
                                    style={{
                                        marginTop: "1rem",
                                    }}
                                >Donate {selectedAmount}</Button>

                            </>
                        )}
                        <Button
                            variant="light"
                            style={{
                                fontSize: "1rem",
                                alignSelf: "center",
                                backgroundColor: "white",
                                color: "gray",
                                borderWidth: "0px",
                                marginTop:"1rem"

                            }}
                            onClick={() => {
                                setSelectedAmount(null);
                                props.setSubscribed(false);
                            }}
                        >Cancel</Button>
                    </div>
                </FadeIn>
            )}

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Thank you for your interest!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>We are collectively pioneering the world's best open-source marine equipment. Many of our projects are truly unique and the first of their kind.</li>
                        <li>Although we are a young community, we are incredibly active. Your support, no matter how small, helps lay the foundation for our continued growth.</li>
                        <li>Donations contribute directly to project costs and keep development moving forward.</li>
                    </ul>

                </Modal.Body>
            </Modal>
        </Form>
    );
};